<template>
  <div>
    <form-create :disabled="!$isPowers(course_id ? 'teachPlanCourseEdit': 'teachPlanCourseCreate') || (course_id && courseInfo.user_id+'' !== userInfo.id+'')"
                 ref="form" v-model="form"
                 :inline="false"
                 label-width="130px"
                 :label-position="'right'"
                 :components="components"
    ></form-create>

    <div style="text-align: right;" v-if="(!course_id && $isPowers('teachPlanCourseCreate')) || (course_id)">
    <!-- <div style="text-align: right;" v-if="!course_id"> -->
      <el-button-group style="padding-top: 15px;">
        <el-button size="small" type="primary" :disabled="loading"  @click="$handleRoute({course_id} , 'teachPlanCourseCont')" v-if="(!course_id && !$isPowers('teachPlanCourseCreate')) || (course_id && !$isPowers('teachPlanCourseEdit')) || (course_id && courseInfo.user_id+'' !== userInfo.id+'')">下一步，教案信息</el-button>
        <el-button size="small" type="primary" :disabled="loading"  @click="submitForm" v-else>保存教案信息</el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import tool from "@/Utils/tool";
import {routeNavigate} from "@/router";

export default {
  props:['course_id'],
  data(){
    var _this = this;
    return {
      form:{},
      loading:false,
      courseInfo:{},
      components:[
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
               {name:'form-item',props:{prop:'course_name',label:'教案名称',required:true }},
              ]},
              {name:'el-col',props:{span:8},components:[
                  {name:'form-item',props:{ prop:'tid',label:'类型' ,required:true,type:'select', data:{ name:"course", keys:"type",isAll:false }}}
                ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{ prop:'cid',label:'类别' ,required:true,type:'select', data:{ name:"course", keys:"cate",isAll:false }}}
              ]}
        ]},

         {name:'el-row' ,components:[
            {name:'el-col',props:{span:16},components:[
              {name:'el-row' ,components:[
              {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'user_id',label:'创建人',required:true ,disabled:true , type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
              ]},
              {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},
              ]},
               ]},
               {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'price',label:'普通使用价格', type:'number',step:0.01 },callback(c){
                    c.props.defaultValue=_this.form.price
                }
                },
              ]},
                {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'price2',label:'高级使用价格', type:'number',step:0.01},callback(c){
                  c.props.defaultValue=_this.form.price2
               }
                },
              ]},
            ]},


            {name:'el-col',props:{span:8},components:[
              {name:'form-item',props:{prop:'cover',required:true,label:'封面',type:'upload', listType:'picture-card',limit:1}},
            ]},
          ]},

          //   {name:'el-row' ,components:[
          //    {name:'el-col',props:{span:12},components:[
          //     {name:'form-item',props:{prop:'user_id',label:'创建人',required:true ,disabled:true , type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
          //     ]},
          //   {name:'el-col',props:{span:12},components:[
          //       {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},
          //     ]},
          // ]},




        // {name:'form-item',props:{prop:'price',required:true,label:'普通使用价格', type:'number',step:0.01 },callback(b){
        //     b.props.disabled = ((_this.courseInfo||{}).id || _this.copyCourseId) && (((_this.courseInfo||{}).powers||{}).publish||[]).indexOf(1) === -1;
        //   }
        // },
        // {name:'form-item',props:{prop:'price2',required:true,label:'高级使用价格', type:'number',step:0.01 },callback(b){
        //     b.props.disabled = ((_this.courseInfo||{}).id || _this.copyCourseId) && (((_this.courseInfo||{}).powers||{}).publish||[]).indexOf(2) === -1;
        //   }
        // },

        // {name:'el-row' ,components:[
        //     {name:'el-col',props:{span:12},components:[
        //         {name:'form-item',props:{prop:'price',label:'普通使用价格', type:'number',step:0.01 },callback(b){
        //     // b.props.disabled = ((_this.courseInfo||{}).id || _this.copyCourseId) && (((_this.courseInfo||{}).powers||{}).publish||[]).indexOf(1) === -1;
   
        //   }
        // },
        //       ]},
        //     {name:'el-col',props:{span:12},components:[
        //         {name:'form-item',props:{prop:'price2',label:'高级使用价格', type:'number',step:0.01},callback(b){
        //     // b.props.disabled = ((_this.courseInfo||{}).id || _this.copyCourseId) && (((_this.courseInfo||{}).powers||{}).publish||[]).indexOf(2) === -1;
        //   }
        // },
        //       ]},
            
        //   ]
        // },
        //  {name:'form-item',props:{prop:'description',required:true,label:'教案描述',type:'textarea',rows:5,resize:'none'}},
        {name:'form-item',props:{prop:'description',label:'教案描述' , type:'editor',editorConfig:{maximumWords:2000},style:{position:'relative',zIndex:0}}},




        // {name:'form-item',props:{prop:'cover',required:true,label:'封面',type:'upload', listType:'picture-card',limit:1}},
        // {name:'form-item',props:{prop:'description',required:true,label:'教案描述',type:'textarea',rows:4,resize:'none'}},
        // {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},
      ],
      isOnlyField:false,
      copyCourseId:null
    }
  },
  //监控data中的数据变化
  watch: {
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!(this.course_id > 0) && !tool.obj_equal(v,o)){
          this.form.user_id = v.id || 0
        }
      },
    }
  },
  created(){
    console.log(this.course_id,"this.course_id")
  },
  methods:{
    routeUpdate(){
      this.getDetail();
    },
    getDetail(){
      if(this.course_id > 0){
        this.loading = true;
        // this.$http.post('/xapi/course.course/detail' ,{id:this.course_id}).then(({data})=>{
        this.$http.post('/xapi/course.course/detail_simplen' ,{id:this.course_id}).then(({data})=>{
          setTimeout(()=>{
            this.courseInfo = data;
          this.form = {...this.courseInfo};

          },100)
        }).finally(()=>{
          this.loading = false;
        });
      }else if(this.$route.query.courseId){
        this.copyCourseId = this.$route.query.courseId || null
        this.loading = true;
        // this.$http.post('/xapi/course.course/detail' ,{id:this.copyCourseId}).then(({data})=>{
        this.$http.post('/xapi/course.course/detail_simplen' ,{id:this.copyCourseId}).then(({data})=>{
          if((data.powers || {}).plan_create){
            this.courseInfo = {...data,id:null,pid:null};
            this.form = {...this.courseInfo};
          }else{
            this.courseInfo = {user_id:this.userInfo.id||0};
            this.form = {...this.courseInfo};
          }
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.courseInfo = {user_id:this.userInfo.id||0};
        this.form = {...this.courseInfo};
      }
    },
    submitForm(){
      this.$refs.form.validate((form,valid , errors)=>{
        if(valid){
          this.loading = true;
          this.$http.post('/xapi/course.course/planSave' ,{
            id:this.course_id
            , ...form
            , courseId : this.copyCourseId
          }).then(({data,msg})=>{
            this.$handleMessage(msg || "保存成功",'success',()=>{
              if(!(this.course_id > 0)){
                this.$handleRoute({course_id:data.id} , 'teachPlanCourseCont')
              }else{
                this.$handleRoute({course_id:this.course_id} , 'teachPlanCourseCont')
              }
            })
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.loading = false;
          this.$handleMessage(errors[0].message , 'error');
        }else{
          this.loading = false;
        }
      } , true)
    },
  }
}
</script>

<style scoped>

</style>